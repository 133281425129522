import React from "react"
import { navigate } from "gatsby"
import { postVote } from "../../../../../api"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Paragraph from "../../../../../components/paragraph"
import TextPlaceholder from "../../../../../components/text-placeholder"
import CourseFooterBool from "../../../../../components/course-footer-bool"
import Heading from "../../../../../components/heading"
import Inline from "../../../../../components/inline"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Text from "../../../../../components/text"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })
  const chapter = {
    courseId: "jahreszeiten",
    chapterId: "02-eigenes-gedicht",
  }

  const silbenAnswer = getAnswer({ ...chapter, taskId: "silben" })
  const ideenAnswer = getAnswer({ ...chapter, taskId: "ideen-sammeln" })
  const rhymes = getAnswer({ ...chapter, taskId: "reim-und-klang" })
  const themaAnswer = getAnswer({ ...chapter, taskId: "thema-waehlen" })
  const hiddenOrder = getAnswer({ ...chapter, taskId: "jahreszeiten" })

  const rhymesArray =
    rhymes &&
    Object.entries(rhymes).reduce((acc, [key, value]) => {
      const index = parseInt(key.replace("word-", ""))
      const current = acc[index] || []
      current.push(value)
      acc[index] = current
      return acc
    }, [])

  const seasons = {
    spring: "Frühling 🌷",
    summer: "Sommer 🌻",
    autumn: "Herbst 🍂",
    winter: "Winter ⛄",
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterBool
          title="Hat dir der Kurs gefallen?"
          onSubmit={async (positive) => {
            try {
              await postVote({
                title: "jahreszeiten",
                upvoted: positive,
              })
            } catch (error) {}
            navigate("/kurse/jahreszeiten/feedback", {
              state: { positive },
            })
          }}
        />
      }
    >
      <Seo title="Dein Schreibplan" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Dein Schreibplan
          </Heading>
        </Stack>
        <Paragraph>Hier eine Zusammenfassung deiner Gedichtideen.</Paragraph>
        <TaskSidebar
          main={
            <Stack>
              <Stack space={6}>
                <Heading as="h2" level={4}>
                  Dein Thema
                </Heading>
                <PaperStack borderColor={themaAnswer?.topic}>
                  <Paragraph>{seasons[themaAnswer?.topic]}</Paragraph>
                </PaperStack>
              </Stack>
              <Stack space={6}>
                <Heading as="h2" level={4}>
                  Deine Ideensammlung
                </Heading>
                <PaperStack>
                  <Paragraph>{ideenAnswer?.idea}</Paragraph>
                </PaperStack>
              </Stack>
              <Stack space={6}>
                <Heading as="h2" level={4}>
                  Dein Reimlexikon
                </Heading>
                <PaperStack>
                  <Stack space={3}>
                    {rhymesArray &&
                      rhymesArray.map((section, index) => (
                        <Inline key={index}>
                          {section.map(
                            (rhyme, index) =>
                              rhyme && (
                                <Box
                                  sx={{
                                    bg: "muted",
                                    fontFamily: "sans",
                                    borderRadius: "9999em",
                                    px: 4,
                                    py: 1,
                                  }}
                                  key={index}
                                >
                                  {rhyme}
                                </Box>
                              )
                          )}
                        </Inline>
                      ))}
                  </Stack>
                </PaperStack>
              </Stack>
              <Stack space={6}>
                <Heading as="h2" level={4}>
                  Schreibschablone
                </Heading>
                <PaperStack>
                  <TextPlaceholder
                    paragraphs={silbenAnswer?.verses}
                    lines={silbenAnswer?.lines}
                    words={silbenAnswer?.words}
                  >
                    {({ paragraph, line, lineContent }) => (
                      <Columns alignY="center">
                        <Column width="100px">
                          <Text sans>
                            {silbenAnswer?.words[paragraph][line]} Silben
                          </Text>
                        </Column>
                        <Column>{lineContent}</Column>
                      </Columns>
                    )}
                  </TextPlaceholder>
                </PaperStack>
              </Stack>
              <Stack space={6}>
                <Heading as="h2" level={4}>
                  Deine versteckte Ordnung
                </Heading>
                <Paragraph>Das wolltest du ausprobieren:</Paragraph>
                <Stack space={6}>
                  {hiddenOrder && hiddenOrder["order1"] && (
                    <PaperStack>
                      <Text size={[3, 3, 4]}>
                        In den Anfangsbuchstaben der Zeilen ein Wort verstecken,
                        wie bei einem Akrostichon. Dafür schreibst du die
                        Buchstaben eines Wortes senkrecht untereinander.
                        Anschließend wird jeder Buchstabe zum Anfangsbuchstaben
                        einer Zeile deines Gedichts.
                      </Text>
                    </PaperStack>
                  )}
                  {hiddenOrder && hiddenOrder["order2"] && (
                    <PaperStack>
                      <Text size={[3, 3, 4]}>
                        Mit Wiederholungen spielen. Beispielsweise mit Worten,
                        Anfangsbuchstaben oder Anlauten, die immer wiederkehren.
                      </Text>
                    </PaperStack>
                  )}
                  {hiddenOrder && hiddenOrder["order3"] && (
                    <PaperStack>
                      <Text size={[3, 3, 4]}>
                        Mit Klängen spielen, indem du beispielsweise nur Worte
                        mit den Vokalen O und A verwendest.
                      </Text>
                    </PaperStack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          }
          sidebar={
            <Note variant="task">
              Jetzt gehts ans Schreiben. Schnapp dir ein Blatt Papier und leg
              los.
            </Note>
          }
        />
        <Paragraph>
          Wenn du magst, kannst du uns dein Gedicht auch zusenden. Wir freuen
          uns über Post an diese Adresse:
        </Paragraph>
        <Paragraph>
          Hölderlinturm Tübingen <br />
          Bursagasse 6 <br />
          72070 Tübingen
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
